:root {
  --primary-color: "#007bff";
  --secondary-color: "#0056b3";
}

body {
  width: 100%;
  max-width: 420px;
  justify-content: center;
  display: flex;
  margin: 0 auto;
}

.headerImg {
  width: 100%;
  height: auto;
  border-radius: 0px 0px 10px 10px;
}

.discount-box,
.delivery-box,
.contact-box {
  background-color: white;
  padding: 25px;
  margin: 15px 0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.discount-box h2,
.delivery-box h2 {
  font-size: 30px;
  margin-bottom: 15px;
  text-align: center;
  color: var(--primary-color);
}

.discount-box p,
.delivery-box p,
.contact-box p {
  font-size: 15px;
  color: #666;
  line-height: 1.6;
}

.discount-box .price,
.delivery-box .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}

.discount-box .price span,
.delivery-box .info span {
  font-size: 30px;
}

.discount-box .price .old-price {
  text-decoration: line-through;
  color: #aaa;
}

.discount-box .price .new-price {
  color: var(--primary-color);
  font-weight: bold;
}

.discount-box button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 20px 25px;
  min-width: 100%;
  font-size: 16px;
  border-radius: 250px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.discount-box button:hover {
  background-color: var(--secondary-color);
}

.delivery-box {
  text-align: left;
}

.delivery-box .info {
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.delivery-box .info:last-child {
  border-bottom: none;
}

.delivery-box .info .Image {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  margin-right: 10px;
  border-radius: 100%;
  color: white;
  background-color: var(--secondary-color);
  text-align: center;
  line-height: 48px;
}

.delivery-box .info .Image .Icon {
  width: 20px;
  height: 20px;
}

.delivery-box .info div {
  flex: 1;
}

.delivery-box .info strong {
  display: block;
  color: #333;
  margin-bottom: 5px;
  font-size: 16px;
}

.delivery-box .info p {
  margin: 0;
  color: #777;
  font-size: 14px;
}

.tech-block {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  margin: 15px 0;
}

.tech-header {
  background-color: #f7f7f7;
  padding: 15px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: var(--primary-color);
}

.tech-block img {
  width: 100%;
  border-radius: 0 0 10px 10px;
}

.tech-description {
  padding: 20px;
  line-height: 1.6;
}

.form-group {
  margin: 12px 0;
  max-width: 100%;
}

.form-group input {
  width: 90%;
  padding: 16px;
  border: none;
  border: solid 1px #ccc;
  border-radius: 250px;
  font-size: 16px;
  margin: 5px 0;
}

.form-group input:focus {
  border-color: var(--primary-color);
  outline: none;
}

.contact-box {
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.contact-box p {
  margin: 8px 0;
  font-size: 15px;
}

.contact-box strong {
  margin-top: 10px;
  color: var(--primary-color);
}

.contact-box a {
  color: var(--primary-color);
  text-decoration: none;
}

.contact-box a:hover {
  text-decoration: underline;
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-right: 8px;
  vertical-align: top;
  margin-bottom: 16px;
  accent-color: white;
  transition: accent-color 0.5s;
}

.privacy_policy {
  font-size: 14px;
  color: #333;
  cursor: pointer;
  vertical-align: top;
}

input[type="checkbox"]:checked {
  accent-color: var(--primary-color);
}